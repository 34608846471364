import React from 'react';

import trustpilotLogoHome from '../assets/images/trustpilot-logo-home.svg';
import trustpilotStarsHome from '../assets/images/trustpilot-stars-home.svg';
import trustpilotLogo from '../assets/images/trustpilot-logo.svg';
import trustpilotStars from '../assets/images/trustpilot-stars.svg';
import trustpilotStarsGreenSmall from '../assets/images/trustpilot-stars-green-small.svg';
import trustpilotLogoGreenSmall from '../assets/images/trustpilot-logo-green-small.svg';
import trustpilotStarsSlider from '../assets/images/trustpilot-stars-slider.svg';

interface ReviewData {
    Name: string;
    Review: string;
    Date: string;
    URL: string;
}

// Define a type for your TrustPilot data
interface TrustPilotData {
	URL: string;
    TrustScore: number;
    Reviews: number;
    Rating: string;
    trustpilotLogoHome: string;
    trustpilotStarsHome: string;
    trustpilotLogo: string;
    trustpilotStars: string;
    trustpilotStarsGreenSmall: string;
	trustpilotLogoGreenSmall: string;
	trustpilotStarsSlider: string;
}

// Define the TrustPilot data
export const TrustPilot: TrustPilotData = {
	URL: 'https://au.trustpilot.com/review/streamline-servers.com',
    TrustScore: 4.6,
    Reviews: 115,
    Rating: 'Excellent',
    trustpilotLogoHome: trustpilotLogoHome,
    trustpilotStarsHome: trustpilotStarsHome,
    trustpilotLogo: trustpilotLogo,
    trustpilotStars: trustpilotStars,
    trustpilotStarsGreenSmall: trustpilotStarsGreenSmall,
	trustpilotLogoGreenSmall: trustpilotLogoGreenSmall,
	trustpilotStarsSlider: trustpilotStarsSlider,
}

export const Reviews: ReviewData[] = [
    {
        Name: "Kiri Bt",
        Review: "The customer support is Top Notch. Highly Recommend.",
        Date: "30 January 2024",
        URL: "https://au.trustpilot.com/reviews/65b8b5c1176c5f4737ed5686",
    },
    {
        Name: "CrossEffex",
        Review: "Outstanding company. I switched to them from a different hoster and was met with excellent support from active staff in there discord. Also very helpful and none of the issues I was having with my prior hoster. Will 100% be using them from now on.",
        Date: "28 January 2024",
        URL: "https://au.trustpilot.com/reviews/65b880ef1157f5ff8a59110e",
    },
    {
        Name: "marvin carrier",
        Review: "They have answered tickets faster and more accurate then all the other companies combined. very polite. set up was super easy. more flexability with there setting options.",
        Date: "29 July 2023",
        URL: "https://au.trustpilot.com/reviews/64c735436b4f56d1e364b0e1",
    },
    {
        Name: "Aussie Exile",
        Review: "Can say that of the handful of server rental services I have used over the last six or seven years with my community that thus far Streamline Servers have been amazing. their customer support have been great in resolving ongoing issues i encountered with me server many of which where due to my own actions and lack of technical knowledge. they are honest and human in their dealings with their customers and strike a great balance between swift professionalism and polite humanitarian ethos when dealing with members of the public that are less informed about tech then they are. I look forward to continued and ongoing work with Streamline Servers in fact I'm off to open a new ticket now. how often can you say you haven't felt dread at the thought of filing a customer support ticket.",
        Date: "14 July 2023",
        URL: "https://au.trustpilot.com/reviews/64b9127464eef47ee8971984",
    },
    {
        Name: "Lotte",
        Review: "Jero and Shaun were just wonderful! Very timely response and helped me out with my concerns right away. Their CS team is like a breath of fresh air after going through a different hosting service that would answer you with a generic reply after almost a week if you're lucky. Hopefully our server will be as dependable as these two! Thanks again!",
        Date: "9 June 2023",
        URL: "https://au.trustpilot.com/reviews/6481c9e9d50cf33972ec0d67",
    },
    {
        Name: "Adrian Apolinario",
        Review: "we've been using Streamline for the past few years now for running Project Zomboid servers. What's only holding us back was the price that they offer for renting their servers. The whole package is great ---- it's complete. It has everything you'd ask for. If you have the money, go for streamline. Really worth the money!",
        Date: "27 Mar 2023",
        URL: "https://au.trustpilot.com/reviews/64200d868b865fe840b2a39f",
    },
    {
        Name: "Dylan",
        Review: "I've been with Streamline consistently since 2015 and have never once had a bad interaction or situation with the staff or services. any time I've had to open a ticket they always responded promptly and done everything they've been able to to help me out, solving my issues every time. personally I'm very selective with my hosts and i don't think i've had another host with as good of customer service as you guys. keep it up",
        Date: "22 Dec 2022",
        URL: "https://au.trustpilot.com/reviews/63a3e2a24d0773066a21a2fd",
    },
    {
        Name: "Stephan Guerry",
        Review: "Every time I could set the server very nicely, and the rare occurrences that I ended having problems the support was very responsive. Very friendly and quick to resolve the issues.",
        Date: "17 June 2020",
        URL: "https://au.trustpilot.com/reviews/5ee939dd7dd7530708857241",
    },
    {
        Name: "Mohree",
        Review: "Have had two issues with the server disappearing from the list in our game. Both times i have send a ticket the issue has been resolved almost immediately, Staff is super friendly and always happy to help and answer any questions.",
        Date: "15 Oct 2022",
        URL: "https://au.trustpilot.com/reviews/6349dffe9b12f67dfee194c8",
    },
    {
        Name: "Nomad",
        Review: "After suffering with other server providers I came across streamline-servers. Server has been running most of the time very well but with occasional hiccup. The support team is dedicated and do go the extra mile to help out. Response from the moment a ticket is submitted to reply is clearly the quickest I have come across. Even evening hours and weekends. The location I contact should I have problem is Australia so I don’t know if other locations keep same high standard.",
        Date: "13 Apr 2018",
        URL: "https://au.trustpilot.com/reviews/5ad0269b6d33bc0abcb6110d",
    },
    {
        Name: "Indy J",
        Review: "Had no issues with my hosting since I started using streamline 6 months ago. The dedicated machine I rent is high quality and allows me to do everything I need. Their ddos protection is the only reliable one in Australia and have had no downtime because of this.",
        Date: "29 Aug 2022",
        URL: "https://au.trustpilot.com/reviews/630c6fa47f7a8621ee4c3736",
    },
    {
        Name: "CampinPalpatine",
        Review: "I have very little experience in running a server or setting it up, luckily the support team can do that for you as long as you send a support ticket. When I wanted to set up a prop hunt game and didn't know how I sent a ticket and left thinking it would be fixed by tomorrow, he replied in 7 minutes and had it fixed in the next 5. Would recommend based on the support.",
        Date: "23 Jan 2022",
        URL: "https://au.trustpilot.com/reviews/61ecfac2e1196e51d76c4ea0",
    },
    {
        Name: "h8n home",
        Review: "Streamline Servers is the best site for renting servers all sorts of games. Streamline has HPGS and other great gaming performances that no one will regret renting it. Server down due to any implications... Streamline Staff responses quickly. Too bad highest rating stars are 5... I will give 11 stars sincerely.",
        Date: "26 May 2020",
        URL: "https://au.trustpilot.com/reviews/5ecc1c4625e5d209b8f448d0",
    },
];

