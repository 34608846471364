import { useEffect, useMemo, useState } from 'react';
import Container from '../layouts/Core/Container';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';
import Travel from '../layouts/Core/Travel';
import { useTawkContext } from '../layouts/TawkContext';

import $ from 'jquery';

import storeWindows from "../assets/images/store-windows.svg";
import storeEpic from "../assets/images/store-epicgames.svg";
import gslMark from '../assets/images/globalsecurelayer-mark.svg';
import ddosShield from '../assets/images/ddos-shield.svg';

import MapGames from '../layouts/MapGames';
import { Nav, Tab } from 'react-bootstrap';
import { useGamesList, GameLookup } from '../layouts/GamesList';
import { useNavigate, useParams } from 'react-router-dom';
import { STANDARD_ROUTE_URIS } from '../routes/Router';
import ErrorPage from './404';

// Magical stuff that makes the page reactive 
export const TABS = {
    FEATURES: 'features',
    DDOS_PROTECTION: 'ddos-protection',
    GAME_PANEL: 'game-panel',
    HPGS_HARDWARE: 'HPGS-hardware',
    LOCATIONS: 'locations',
    SUPPORT: 'support',
    MODPACKS: 'modpacks',
	FAQ: 'faq'
};

export default function GameServersInnerPage() {
	const { currencySign } = useSharedCurrencyState();

    const navigate = useNavigate();

    const params = useParams();
	const gamesList = useGamesList();
    const gameKey = params['game'];

    // You should implement the game servers stuff like:
    const gameDetails = useMemo(() => {
        return GameLookup(gameKey, gamesList)
    }, [gameKey, gamesList]);

    const isGameFound = useMemo(() => {
        try {
            require(`../assets/images/games/${gameDetails.logoImage}`);
    
            return true;
        } catch (err) {
            return false;
        }
    }, []);

    const [selectedTab, setSelectedTab] = useState(TABS.FEATURES);
    	
	const { handleMaximize } = useTawkContext();
	
	const [imageClicked, setImageClicked] = useState(false);

	const handleImageClick = () => {
		setImageClicked(true);
	};

	const handleOverlayClick = () => {
		setImageClicked(false);
	};

    // Little initial hook where we boot out of here if something invalid is chosen.
    if(gameDetails.key === undefined) {
        navigate(STANDARD_ROUTE_URIS.GAME_SERVERS, {replace: true});
    }

    useEffect(() => {
        if(isGameFound) {
            var $window = $(window),
            $mainMenuBar = $('#green-bar-nav'),
            $mainMenuBarAnchor = $('#greenBarAnchor'),
            sections = $('.feature-box'),
            nav_height = $('header.header').outerHeight() + $('div.top-bar').outerHeight();
    
            $window.on('scroll', function() {
                nav_height = $('header.header').outerHeight() + $('div.top-bar').outerHeight();
    
                // Handle tab changes from scrolling.
                var cur_pos = $(this).scrollTop();
    
                sections.each(function(e) {
                    var top = $(sections[e]).offset().top - (nav_height + $mainMenuBar.outerHeight()),
                    bottom = top + $(sections[e]).outerHeight();
    
                    if (cur_pos >= top && cur_pos <= bottom) {
                        setSelectedTab($(sections[e]).attr('id'));
                    }
                });
    
                // Make the green menu bar stick when we scroll past it.
                var window_top = $window.scrollTop();
                var div_top = $mainMenuBarAnchor.offset().top;
    
                if (window_top > (div_top - ($mainMenuBar.outerHeight() * 1.80))) {
                    // Make the div sticky.
                    $mainMenuBar.addClass('stick');
                    $mainMenuBar.css('top', `${nav_height}px`);
                    $mainMenuBarAnchor.height($mainMenuBar.outerHeight());
                } else {
                    // Unstick the div.
                    $mainMenuBar.removeClass('stick');
                    $mainMenuBar.css('top', null);
                    $mainMenuBarAnchor.height(0);
                }
            });
    
            return () => {
                $window.off('scroll');
            };
        }
        else {
            return () => {}
        }
    }, [gameKey]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleSmoothScroll = (id) => {
        const element = $(`#${id}`);

        if (element) {
            $('html, body').animate({
                scrollTop: element.offset().top - 150
              }, 300);
        }
    }
	
	const unitPrice = () => {
        if(gameDetails.perSlot !== "0.00" && gameDetails.slotLimit !== "0-0" && gameDetails.perSlot !== null) {
            return <li><h2 className="font-30">{currencySign}{gameDetails.perSlot}<span className="font-15 block green">per slot</span></h2></li>;
		} else {
			return <li><h2 className="font-30">{currencySign}{gameDetails.startingAt}<span className="font-15 block green">per Month</span></h2></li>;
		}
    }
	
	const unitLimit = () => {
        if(gameDetails.perSlot !== "0.00" && gameDetails.slotLimit !== "0-0" && gameDetails.slotLimit !== "null-null") {
            return <li><h2 className="font-30">{gameDetails.slotLimit}<span className="font-15 block green">slot limit</span></h2></li>;
        } else if(gameDetails.gbLimit !== "0-0") {
			return <li><h2 className="font-30">{gameDetails.gbLimit}<span className="font-14">GB</span><span className="font-15 block green">RAM limit</span></h2></li>;
		}
    }
	
	const unitLocations = () => {
        if(gameDetails.slotLimit !== "0-0" && gameDetails.gbLimit !== "0-0" && gameDetails.perSlot !== "0.00") {
            return <li><h2 className="font-30">{gameDetails.gbLimit}<span className="font-14">GB</span><span className="font-15 block green">RAM limit</span></h2></li>;
        } else {
			return <li><h2 className="font-30">{gameDetails.locations}<a onClick={function(){handleSmoothScroll(TABS.LOCATIONS)}} href={`#/game-servers/${gameDetails.key}#${TABS.LOCATIONS}`} className="font-15 block green locations-link">locations</a></h2></li>;
		}
    }

    const availableOn = () => {
        if(gameDetails !== undefined) {
            switch(gameDetails.purchaseOn) {
                case "steam":
                    return <strong><a href={gameDetails.storeLink} target="_blank" className="white-link" ><i className="fa-brands fa-steam ml-1 mr-1 store-steam"></i>Steam</a></strong>;
				case "windows":
                    return <strong><a href={gameDetails.storeLink} target="_blank" className="white-link" ><i className="ml-1 mr-1"><img src={storeWindows} className="store-windows" /></i>Windows Store</a></strong>;
				case "epic":
                    return <strong><a href={gameDetails.storeLink} target="_blank" className="white-link" ><i className="ml-1 mr-1"><img src={storeEpic} /></i>Epic Games</a></strong>;
            }
        }
    }

    return isGameFound ? (
        <Container title={(gameDetails.name) + " Server Hosting | Streamline-Servers"} description={"Rent your own " + (gameDetails.name) + " Game Server for as little as " + (currencySign) + (gameDetails.startingAt) + "/mo. Streamline-Servers, Global, Fast, Secure, & Affordable Hosting Since 2007!"} className={`page-game-servers page-game-inner`} bodyImage={gameDetails.backgroundImage}>
            <section className="sub-hero hero-text-left" id="hero-inner">
                <div className="container relative">
					<Travel to={STANDARD_ROUTE_URIS.GAME_SERVERS} className="label back-label absolute-left back-nav-games">&laquo; GAME SERVERS</Travel>
                    <div className="row">
                    <div className="col col-lg-6 pr-5 prfix">
                        <h1 className="game-header-name">
                            {gameDetails.name}
                            {gameDetails.earlyaccess && (
                                <span className="tooltip-top tooltip-ea" data-tooltip="This game is Early Access, which means there will be bugs and unoptimized server software. Support also may be limited.">
                                <span className="early-access-games">EARLY ACCESS</span>
                                </span>
                            )}
                        </h1>
                        <p className="font-18 ml-0" dangerouslySetInnerHTML={{__html: gameDetails.description!}}></p>
                        <strong className="font-18">{gameDetails.hook}</strong>
                    </div>
                    <div className="col col-lg-6 text-center">
                        <img src={require(`../assets/images/games/${gameDetails.logoImage}`)} className="block mx-auto mb-3 games-inner-logo" />
						{gameDetails.stock ? (
							<ul className="mb-4" id="game-details-list">
							{unitPrice()}
							{unitLimit()}
							{unitLocations()}
							</ul>
						) : (
							<ul className="mb-4" id="game-details-list">
							</ul>
						)}
						{gameDetails.comingsoon ? (
							<a href="javascript:void(0)" className="button out-of-stock"><i className="fa-regular fa-clock mr-1"></i>Coming Soon</a>
							) : gameDetails.stock ? (
							<a href={gameDetails.orderLink} className="button">Create Your Server <i className="fa-solid fa-arrow-right-long ml-1"></i></a>
							) : (
							<a href="javascript:void(0)" className="button out-of-stock"><i className="fa-regular fa-ban mr-1"></i>Out of Stock</a>
						)}
                        <div className="block mt-3 pt-1 font-14">
                        <span className="block">Available for purchase and download on {availableOn()}</span>
                        <a href={gameDetails.youtubeLink} target="_blank" className="white-link"><i className="fa-solid fa-play font-10 mr-1"></i> <strong>watch the trailer</strong></a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <div id="greenBarAnchor"></div>
            <div className="green-bar-nav" id="green-bar-nav">
                <div className="container">
                    <div className="feature-nav">
                        <ul id="gameserver-nav">
                            {
                                gameDetails.tabs!.includes(TABS.FEATURES) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.FEATURES)}} href={`#/game-servers/${gameDetails.key}#${TABS.FEATURES}`} className={`${TABS.FEATURES === selectedTab ? 'active' : ''}`}>Features</a></li>
                                )
                            }
                            {
                                gameDetails.tabs!.includes(TABS.DDOS_PROTECTION) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.DDOS_PROTECTION)}} href={`#/game-servers/${gameDetails.key}#${TABS.DDOS_PROTECTION}`} className={`${TABS.DDOS_PROTECTION === selectedTab ? 'active' : ''}`}>DDoS Protection</a></li>
                                )
                            }
                            {
                                gameDetails.tabs!.includes(TABS.GAME_PANEL) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.GAME_PANEL)}} href={`#/game-servers/${gameDetails.key}#${TABS.GAME_PANEL}`} className={`${TABS.GAME_PANEL === selectedTab ? 'active' : ''}`}>Game Panel</a></li>
                                )
                            }
							{
                                gameDetails.tabs!.includes(TABS.HPGS_HARDWARE) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.HPGS_HARDWARE)}} href={`#/game-servers/${gameDetails.key}#${TABS.HPGS_HARDWARE}`} className={`${TABS.HPGS_HARDWARE === selectedTab ? 'active' : ''}`}>HPGS</a></li>
                                )
                            }
							{
                                gameDetails.tabs!.includes(TABS.MODPACKS) && (
                                    <li><a  onClick={function(){handleSmoothScroll(TABS.MODPACKS)}}href={`#/game-servers/${gameDetails.key}#${TABS.MODPACKS}`} className={`${TABS.MODPACKS === selectedTab ? 'active' : ''}`}>Modpacks</a></li>
                                )
                            }
                            {
                                gameDetails.tabs!.includes(TABS.LOCATIONS) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.LOCATIONS)}} href={`#/game-servers/${gameDetails.key}#${TABS.LOCATIONS}`} className={`${TABS.LOCATIONS === selectedTab ? 'active' : ''}`}>{gameDetails.locations} Locations</a></li>
                                )
                            }
							{
                                gameDetails.tabs!.includes(TABS.FAQ) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.FAQ)}} href={`#/game-servers/${gameDetails.key}#${TABS.FAQ}`} className={`${TABS.FAQ === selectedTab ? 'active' : ''}`}>FAQ</a></li>
                                )
                            }
                            {
                                gameDetails.tabs!.includes(TABS.SUPPORT) && (
                                    <li><a onClick={function(){handleSmoothScroll(TABS.SUPPORT)}} href={`#/game-servers/${gameDetails.key}#${TABS.SUPPORT}`} className={`${TABS.SUPPORT === selectedTab ? 'active' : ''}`}>24/7 Support</a></li>
                                )
                            }
                        </ul>
                    </div>
					{gameDetails.comingsoon ? (
						<ul id="gameserver-nav-order">
						<li>
							<a href="javascript:void(0)" className="button out-of-stock dark"><i className="fa-regular fa-clock mr-1"></i>Coming Soon</a>
						</li>
						</ul>
					) : gameDetails.stock ? (
						<ul id="gameserver-nav-order">
						<li className="mr-4">
							<span className="font-12 block">Starting at</span>
							<h4 className="font-22 mt-n1">{currencySign}{gameDetails.startingAt}<small className="font-16">/mo</small></h4>
						</li>
						<li>
							<a href={gameDetails.orderLink} className="button dark">order now <i className="fa-solid fa-arrow-right-long ml-1"></i></a>
						</li>
						</ul>
					) : (
						<ul id="gameserver-nav-order">
						<li>
							<a href="javascript:void(0)" className="button out-of-stock dark"><i className="fa-regular fa-ban mr-1"></i>Out of Stock</a>
						</li>
						</ul>
					)}
                </div>
            </div>
            {
                gameDetails.tabs!.includes(TABS.FEATURES) && (
                    <section className="feature-box features" id={TABS.FEATURES}>
                        <div className="container">
                            <div className="title">
                            <h2><small>What's Included?</small>Game Server Features</h2>
                            <p>
                                All our game servers include the latest features to ensure the best performance
                            </p>
                            </div>
                            <div className="padded-row-big">

                            <div className="row pt-3 ptfix">
                                <div className="col col-md-6 col-lg-6 pr-5 prfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-clock va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-clock va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">Instant Deployment</h3>
                                    <p className="pt-1 pb-0">
                                    Upon payment, our control panel facilitates the instant installation of game servers, ensuring a hassle-free and streamlined experience for gamers.
                                    </p>
                                </div>
                                </div>
                                <div className="col col-md-6 col-lg-6 pl-5 plfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-chart-network va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-chart-network va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">Low Latency</h3>
                                    <p className="pt-1 pb-0">
                                    Our low latency network provides gamers with a smooth and responsive online gaming experience, minimizing delays and ensuring optimal gameplay.
                                    </p>
                                </div>
                                </div>
                                <div className="col col-md-6 col-lg-6 pr-5 prfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-shield va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-shield va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">DDoS Protection</h3>
                                    <p className="pt-1 pb-0">
                                    We partner with Global Secure Layer to offer robust DDoS protection, safeguarding game servers against malicious attacks and ensuring uninterrupted gameplay for our customers.
                                    </p>
                                </div>
                                </div>
                                <div className="col col-md-6 col-lg-6 pl-5 plfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-hard-drive va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-hard-drive va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">Latest Hardware</h3>
                                    <p className="pt-1 pb-0">
                                    Our server hardware utilizes the latest cutting-edge technology, ensuring superior performance and reliability for an immersive gaming experience even with the newest game releases.
                                    </p>
                                </div>
                                </div>
                                <div className="col col-md-6 col-lg-6 pr-5 prfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-backpack va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-backpack va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">Data Protection</h3>
                                    <p className="pt-1 pb-0">
                                    With a robust server redundancy system and reliable backups in place, we ensure the highest level of data protection, minimizing the risk of data loss and maximizing the availability of game servers for uninterrupted gameplay.
                                    </p>
                                </div>
                                </div>
                                <div className="col col-md-6 col-lg-6 pl-5 plfix">
                                <div className="absolute-left-parent">
                                    <div className="absolute-left">
                                    <i className="fa-solid fa-square-up va-mid absolute-left font-60 grad-behind-icon"></i>
                                    <i className="fa-thin fa-square-up va-mid absolute-left font-60 green-icon"></i>
                                    </div>
                                    <h3 className="font-22 pt-2">Always Up-To-Date</h3>
                                    <p className="pt-1 pb-0">
                                    We continuously update and upgrade all our systems with the latest technology to ensure optimal performance, security, and compatibility, giving our customers peace of mind that their game servers will continue to run with every update.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                )
            }
            {
                gameDetails.tabs!.includes(TABS.DDOS_PROTECTION) && (
                    <section className="feature-box py-5 light-bg" id={TABS.DDOS_PROTECTION}>
                        <div className="container py-3">
                            <div className="row">
                            <div className="col col-lg-4 text-center">
                                <img src={ddosShield} className="mobile-icon-height" />
                            </div>
                            <div className="col col-lg-8 mtfix ptfix">
                                <h2 className="pt-5 ptfix"><small>INCLUDED DDOS PROTECTION</small>Making Sure Your Server is Always Online!</h2>
                                <p className="pt-1">
                                Streamline-Servers has formed a strategic partnership with Global Secure Layer, a renowned cybersecurity firm known for their latest DDoS technology. This collaboration empowers us to offer our clients robust protection against DDoS attacks by integrating Global Secure Layer's cutting-edge mitigation solution into our infrastructure. Together, we ensure uninterrupted availability and optimal performance of our clients' online services, leveraging advanced technologies to swiftly detect and mitigate malicious traffic. Through this partnership, Streamline-Servers reaffirms its commitment to delivering top-tier DDoS Protection solutions and providing clients with the peace of mind they deserve.
                                </p>
                                <strong className="font-18 block pb-1 pt-2">DDoS Protection by <img src={gslMark} className="ml-1 mr-1 gsl-mark-sm" />Global Secure Layer</strong>
                                <a href="https://globalsecurelayer.com/ddos-protection" className="strong">Find our more <i className="fa-sharp fa-solid fa-circle-chevron-right font-11"></i></a>
                            </div>
                            </div>
                        </div>
                    </section>
                )
            }
            {
                gameDetails.tabs!.includes(TABS.GAME_PANEL) && (
                    <section className="feature-box" id={TABS.GAME_PANEL}>
                        <div className="container">
                        <Tab.Container defaultActiveKey={"one"}>
                                    <div className="title">
                                        <h2><small>How to manage your Game Server</small>Advanced Game Panel</h2>
                                        <p>
                                            We use a heavily modified version of <a href="https://www.tcadmin.com/">TCAdmin</a> to provide full customization
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col col-md-12 col-lg-6">
											<Tab.Content>
											  <Tab.Pane eventKey={"one"}>
												<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
												  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
												  <img
													src={require("../assets/images/game-panel-1.webp")}
													onClick={handleImageClick}
												  />
												  {imageClicked && (
													<img
													  src={require("../assets/images/game-panel-1-full.webp")}
													  className='responsive-img enlarged'
													  onClick={handleOverlayClick}
													/>
												  )}
												</div>
											  </Tab.Pane>
											  <Tab.Pane eventKey={"two"}>
												<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
												  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
												  <img
													src={require("../assets/images/game-panel-2.webp")}
													onClick={handleImageClick}
												  />
												  {imageClicked && (
													<img
													  src={require("../assets/images/game-panel-2-full.webp")}
													  className='responsive-img enlarged'
													  onClick={handleOverlayClick}
													/>
												  )}
												</div>
											  </Tab.Pane>
											  <Tab.Pane eventKey={"three"}>
												<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
												  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
												  <img
													src={require("../assets/images/game-panel-3.webp")}
													onClick={handleImageClick}
												  />
												  {imageClicked && (
													<img
													  src={require("../assets/images/game-panel-3-full.webp")}
													  className='responsive-img enlarged'
													  onClick={handleOverlayClick}
													/>
												  )}
												</div>
											  </Tab.Pane>
											  <Tab.Pane eventKey={"four"}>
												<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
												  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
												  <img
													src={require("../assets/images/game-panel-4.webp")}
													onClick={handleImageClick}
												  />
												  {imageClicked && (
													<img
													  src={require("../assets/images/game-panel-4-full.webp")}
													  className='responsive-img enlarged'
													  onClick={handleOverlayClick}
													/>
												  )}
												</div>
											  </Tab.Pane>
											</Tab.Content>
                                        </div>
                                            <div className="col col-md-12 col-lg-5 ml-auto">
                                                <Nav role="tablist" className="panel-tab-list mt-4 pt-1 ptfix mtfix d-block">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={"one"} className="panel-tab-box" as='div'>
                                                            <h5><i className="fa-light fa-gauge-simple-max font-24 mr-3 va-mid"></i>Modern and Functional</h5>
                                                            <p className="pb-0">
                                                            Designed to be sleek and adaptable with its dark and light modes. Effortlessly access advanced features that cater to users of all levels, whether you're a beginner or a seasoned expert.
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item>
                                                        <Nav.Link eventKey={"two"} className="panel-tab-box">
                                                            <h5><i className="fa-light fa-pen-to-square font-24 mr-3 va-mid"></i>Configuration Editor</h5>
                                                            <p className="pb-0">
                                                            No need to continuously upload and download your server configuration files, our game panel comes with in-built features that include a Configuration Editor.
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item>
                                                        <Nav.Link eventKey={"three"} className="panel-tab-box">
                                                            <h5><i className="fa-light fa-square-terminal font-24 mr-3 va-mid"></i>Live Web Console</h5>
                                                            <p className="pb-0">
                                                            Our game panel provides a live console log for your game server, enabling real-time monitoring and tracking of server activities right from your browser.
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item>
                                                        <Nav.Link eventKey={"four"} className="panel-tab-box">
                                                            <h5><i className="fa-light fa-download font-24 mr-3 va-mid"></i>Easy Updating</h5>
                                                            <p className="pb-0">
                                                            With just one click, you can directly download updates from Steam servers. Moreover, our panel offers an Task Scheduler to allow automatic update feature for added convenience.
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                </Tab.Container>
                        </div>
                    </section>
                )
            }
			{
                gameDetails.tabs!.includes(TABS.HPGS_HARDWARE) && (
                <section className="feature-box light-bg pt-5" id={TABS.HPGS_HARDWARE}>
                    <div className="container">
                        <div className="row">
                        <div className="col col-lg-8 pr-5 prfix m-order-2">
                            <h2 className="pt-4 ptfix mt-2 mtfix"><small>UPGRADE TO HIGH PERFORMANCE</small>HPGS - High Performace Game Servers</h2>
                            <p className="pt-1">
                            Introducing our latest line of game server systems for extreme performance. These servers boast the latest and greatest hardware for the gaming scene. Over countless hours we have tinkered with these systems in order to give them the best performance without compromise.
                            </p>
                            <strong className="block">Overclock your game server today!</strong>
                        </div>
                        <div className="col col-lg-4 m-order-1">
                            <img src={require("../assets/images/server-hardware.webp")}/>
                        </div>
                        </div>
                        <div className="row pt-4" id="inner-hardware-features">
                        <div className="col col-md-6 col-lg-3">
                            <h5><i className="fa-light fa-gauge-circle-bolt font-24 mr-3 va-mid green"></i>Overclocked CPUs</h5>
                            <p className="pt-3 pb-0">
                            We install the best Air/Water Cooling in our HPGS range, this enables us to gain stable overclocked CPU speeds 24/7.
                            </p>
                        </div>
                        <div className="col col-md-6 col-lg-3">
                            <h5><i className="fa-light fa-boxes-stacked font-24 mr-3 va-mid green"></i>No Overselling</h5>
                            <p className="pt-3 pb-0">
                            We prioritize resources for your game server, avoiding overselling on hardware and ensuring optimal performance.
                            </p>
                        </div>
                        <div className="col col-md-6 col-lg-3">
                            <h5><i className="fa-light fa-route font-24 mr-3 va-mid green"></i>World Wide Locations</h5>
                            <p className="pt-3 pb-0">
                            Our extensive HPGS range spans 20+ global locations, constantly expanding to reach even more corners of the world.
                            </p>
                        </div>
                        <div className="col col-md-6 col-lg-3">
                            <h5><i className="fa-light fa-fire-flame-curved font-24 mr-3 va-mid green"></i>HPGS Plus</h5>
                            <p className="pt-3 pb-0">
                            Want more? Upgrade to <strong>HPGS Plus</strong>!
							<br />
							With our new hardware, we are now able to offer 5GHz+ CPU speeds.
                            </p>
                        </div>
                        </div>
                    </div>
                </section>
                )
            }
            {
                gameDetails.tabs!.includes(TABS.MODPACKS) && (
                    <section className="feature-box pt-6 pb-6" id={TABS.MODPACKS}>
                        <div className="container">
                            <div className="row">
                            <div className="col col-lg-5 pt-1 ptfix mt-1 mtfix">
                                <h2><small>MODPACK MANAGER</small>Easily Install Modpacks and Plugins in 1-Click</h2>
                                <p className="pt-1">
                                Our user-friendly game panel facilitates hassle-free one-click installations of modpacks, plugins, and <strong>Steam Workshop</strong> content, making gaming customization a breeze.
								</p>
								<p className="pt-1">
								Additionally, our mod manager is regularly updated with popular third-party mods. If you can't find a specific mod, just reach out to our support team, and we'll gladly add it for you!
                                </p>
                            </div>
                            <div className="col col-lg-6 ml-auto">
                                <img src={require("../assets/images/one-click-mods.webp")} className="responsive my-n5 mx-n5" />
                            </div>
                            </div>
                        </div>
                    </section>
                )
            }
            {
                gameDetails.tabs!.includes(TABS.LOCATIONS) && (
                    <section className={"feature-box" + (gameDetails.tabs!.includes(TABS.MODPACKS) ? " light-bg" : "")} id={TABS.LOCATIONS}>
                        <div className="container">
                            <div className="title">
                            <h2><small>Locations</small>World Wide Locations</h2>
                            <p>
                                Our servers are currently available in the following locations.
                            </p>
                            </div>
        
                            <MapGames locations={gameDetails.everylocation} />
                        </div>
                    </section>
                )
            }
			{
				gameDetails.tabs!.includes(TABS.MODPACKS) ? (
					""
				) : (
					<div className="container">
						<div className="line-gradient-divider"></div>
					</div>
				)
			}
			{
                gameDetails.tabs!.includes(TABS.FAQ) && (
					<section className="feature-box pb-5" id={TABS.FAQ}>
						<div className="container">
							<div className="title">
							<h2><small>GAME SERVER FAQ</small>Frequently Asked Questions</h2>
							</div>
							<div className="row">
							<div className="col col-lg-8 mx-auto">
								<div className="accordions" id="accordionFAQ">
								<div className="accordion-item faq-item">
									<h2 className="accordion-header" id="headingOne">
										<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
											{gameDetails.customFAQ1q ? (
												gameDetails.customFAQ1q
											) : (
												"What amount of resources do you suggest starting with?"
											)}
										</button>
									</h2>
									<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
									<div className="accordion-body">
										{gameDetails.customFAQ1 ? (
												<p className="pb-0">{gameDetails.customFAQ1}</p>
											) : (
												<p className="pb-0">If you are running an unmodded server, generally the standard settings will run everything smoothly. We suggest starting low as you can always upgrade after purchasing.</p>
										)}
									</div>
									</div>
								</div>
								<div className="accordion-item faq-item">
									<h2 className="accordion-header" id="headingTwo">
										<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											{gameDetails.customFAQ2q ? (
												gameDetails.customFAQ2q
											) : (
												"Can I increase/decrease my Slots, CPU or RAM after purchasing?*"
											)}
										</button>
									</h2>
									<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
									<div className="accordion-body">
										{gameDetails.customFAQ2 ? (
												<p className="pb-0">{gameDetails.customFAQ2}</p>
											) : (
												<p className="pb-0">Yes, you can upgrade at anytime, we have a knowledgebase article on how to do this <a href="https://billing.streamline-servers.com/knowledgebase/12/How-do-I-upgradeordowngrade-my-service.html">here</a>.<p className="font-12">*Slots, CPU and RAM options may not be available for every game.</p></p>
										)}
									</div>
									</div>
								</div>
								<div className="accordion-item faq-item">
									<h2 className="accordion-header" id="headingThree">
										<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
											{gameDetails.customFAQ3q ? (
												gameDetails.customFAQ3q
											) : (
												"What Game Panel do you use?"
											)}
										</button>
									</h2>
									<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
									<div className="accordion-body">
										{gameDetails.customFAQ3 ? (
												<p className="pb-0">{gameDetails.customFAQ3}</p>
											) : (
												<p className="pb-0">We use a heavily customized version of TCAdmin, we have a fully custom built theme along with custom scripts running in the background to manage things.</p>
										)}
									</div>
									</div>
								</div>
								<div className="accordion-item faq-item">
									<h2 className="accordion-header" id="headingFour">
										<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
											{gameDetails.customFAQ4q ? (
												gameDetails.customFAQ4q
											) : (
												"Can I schedule automatic server restarts?"
											)}
										</button>
									</h2>
									<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
									<div className="accordion-body">
										{gameDetails.customFAQ4 ? (
												<p className="pb-0">{gameDetails.customFAQ4}</p>
											) : (
												<p className="pb-0">Yes, our Game Panel allows you to schedule restarts to happen, there are also options to only perform this when the server is empty, see our knowledgebase on this <a href="https://billing.streamline-servers.com/knowledgebase/4352/Auto-Restart-with-Warning-Messages-using-TCAdmin-Scheduled-Tasks.html">here</a>.</p>
										)}
									</div>
									</div>
								</div>
								<div className="accordion-item faq-item">
									<h2 className="accordion-header" id="headingFive">
										<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
											{gameDetails.customFAQ5q ? (
												gameDetails.customFAQ5q
											) : (
												"Do you have trial servers?"
											)}
										</button>
									</h2>
									<div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
									<div className="accordion-body">
										{gameDetails.customFAQ5 ? (
												<p className="pb-0">{gameDetails.customFAQ5}</p>
											) : (
												<p className="pb-0">Whilst we do not have trial servers, we do have a 48 hour (no questions asked) refund period.</p>
										)}
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
					</section>
				)
            }

			{
                gameDetails.tabs!.includes(TABS.SUPPORT) && (
                    <section className="feature-box pt-6 mb-5" id={TABS.SUPPORT}>
                        <div className="container">
                            <div className="cta-gradient-box">
								<div className="row">
									<div className="col">
									<h2><small>24/7/365 SUPPORT</small>Support you can count on!</h2>
									<p>
										Our team is always here to help. Whether you are stuck on configuring your game server or installing a mod, we can help!
									</p>
									<a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="button outline mt-1 mr-3">SUPPORT TICKET <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
									<a href="javascript:void(0);" onClick={handleMaximize} className="button mt-1">Sales Live Chat</a>
									</div>
								</div>
                            </div>
                        </div>
                    </section>
					
                )
            }

            <section className="feature-box green-bg bg-cta-game-list" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-8 col-xl-6">
                        <h2>Deploy your Game Server!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Support Ticket</a>
                        </li>
                        <li className="inline-block pr-3">
                           <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-4 col-xl-6 text-center">
                        <Travel to="#" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></Travel>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
    ) : <ErrorPage/>;
}